select {
  padding: 2px;
  border: 1px solid #cccccc;
  outline: none;
  display: inline-block;
  height: 28px;
  min-width: 200px;
}

/* turn it off completely */
select:active,
select:hover {
  outline: none;
}

.styled-select {
  box-shadow: none;
  appearance: none;
  background-color: white;
  text-indent: 1px;
  max-width: 250px;
}

select::-ms-expand {
  /* for IE 11 */
  display: none;
}

.selectie {
  margin-top: 10px;
  margin-bottom: 30px;
  border: #e7e7e7;
  //border-top: 1px solid #e7e7e7;
  border-style: none;
  min-width: 600px;
}
